/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDChgncSHWJSJ-vjbwfLAaSNFIFVIrmMeU",
  "appId": "1:679323487566:web:f92f78c2fdb89c27a5e21f",
  "authDomain": "schooldog-i-echols-ga.firebaseapp.com",
  "measurementId": "G-GFJJ8W1R5Y",
  "messagingSenderId": "679323487566",
  "project": "schooldog-i-echols-ga",
  "projectId": "schooldog-i-echols-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-echols-ga.appspot.com"
}
